import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class JsPage extends Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = this.props.data.allMarkdownRemark.edges

    const displayPosts = posts.map((post, index) => {
      return (
        <div key={index}>
          <Link to={post.node.fields.slug}>
            <h3>{post.node.frontmatter.title}</h3>
          </Link>
        </div>
      )
    })
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="JavaScript Precourse Section" />
        <h1>JavaScript</h1>
        {displayPosts}
      </Layout>
    )
  }
}

export default JsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { fileAbsolutePath: { regex: "//javascript/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            order
            title
          }
        }
      }
    }
  }
`
